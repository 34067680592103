import Vue, {VNode} from 'vue';
import Inputmask from 'inputmask';
import {DirectiveBinding} from 'vue/types/options';

Vue.directive('mask', {
  bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
    const input: any = el.querySelector(':scope > input');
    if (input) {
      Inputmask(binding.value, {autoUnmask: true}).mask(input);

      input.addEventListener('paste', (e) => {
        // paste was not changing the value of the v-model
        const event = document.createEvent('HTMLEvents');
        event.initEvent('input', true, true);
        e.currentTarget.dispatchEvent(event);
      });
    }
  },
});

/**
 * Apenas numeros
 */
Vue.directive('number', {
    bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        const input: any = el.querySelector(':scope > input');
        if (input) {
            input.addEventListener('input', (e) => {
                input.value = e.target.value.trim().replace(/\D/g, '');
            });
        }
    },
});

/**
 * Valor monetario
 */
Vue.directive('currency', {
    bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
        const input: any = el.querySelector(':scope > input');
        const currency: any = {
            alias: 'currency',
            radixPoint: ',',
            groupSeparator: '.',
            numericInput: true,
            rightAlign: true,
            autoUnmask: true,
            removeMaskOnSubmit: true,
            onBeforeMask: (value, opts) => {
                return parseFloat(value).toFixed(2);
            },
            onUnMask: (maskedValue, unmaskedValue) => {
                maskedValue = maskedValue.replaceAll('.', '');
                maskedValue = maskedValue.replace(',', '.');
                return maskedValue;
            },
        };

        if (input) {
            Inputmask(binding.value, currency).mask(input);
        }
    },
});

export default Inputmask;
