









import {Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import VueRecaptcha from 'vue-recaptcha';
import {AppInfo} from '@/models/AppInfo';
import {namespace} from 'vuex-class';

const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'Recaptcha',
    components: {VueRecaptcha},
})
export default class Recaptcha extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    public $refs!: {
        recaptcha: any,
    };

    public isChecked: boolean = false;

    private onExpired(): void {
        this.isChecked = false;
    }

    private onVerify(response): void {
        this.isChecked = true;
    }
}
