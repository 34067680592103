import Vue from 'vue';

Vue.prototype.$copyToClipboard = (value: string) => {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    Vue.$toast.info('Copiado para o clipboard com sucesso!');
};

Vue.prototype.$isEmpty = (value: string) => {
    return value === null || value === '' || value === undefined || value === 'undefined';
};
