import {Module, Mutation, VuexModule} from 'vuex-module-decorators';

@Module({namespaced: true})
export class LoadingStore extends VuexModule {

    public calledLoadList: string[] = [];
    public isLoading: boolean = false;

    @Mutation
    public async startLoading(calledLoad: string): Promise<void> {
        this.calledLoadList.push(calledLoad);
        this.isLoading = true;
    }

    @Mutation
    public async stopLoading(calledLoad: string): Promise<void> {
        const index = this.calledLoadList.indexOf(calledLoad, 0);
        if (index > -1) {
            this.calledLoadList.splice(index, 1);
            if (this.calledLoadList.length === 0) {
                this.isLoading = false;
            }
        }
    }
}
export default LoadingStore;
