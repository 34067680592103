import Vue from 'vue';
import './plugins/moment';
import './plugins/fontawesome';
import './plugins/toast';
import './plugins/element';
import './plugins/jwt';
import './plugins/datatables';
import './plugins/axios';
import './plugins/vueinputmask';
import './plugins/utils';
import './plugins/formwizard';
import Filters from '@/utils/Filters';
import './scss/style.scss';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(Filters);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
