












import {Component, Vue} from 'vue-property-decorator';
import {AppInfo} from '@/models/AppInfo';
import {namespace} from 'vuex-class';

const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'Copyright',
})
export default class Copyright extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    public visit() {
        window.open('https://www.tn3.com.br/', '_blank');
    }
}
