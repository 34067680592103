import {Vue} from 'vue-property-decorator';

export class Utils {

    // formatter para Decimal pt-BR
    public static formatterMoneyBR = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
    });

    /**
     * Junta String do Cnpj com Razao social
     */
    public static joinCnpjRazao(cnpj = '', razao = ''): string {
        if (cnpj === '' && razao === '') {
            return '';
        }

        let str = '';
        const separator = ' / ';
        if (cnpj !== '') {
            str += cnpj;
        }
        if (razao !== '') {
            if (cnpj !== '') {
                str += separator;
            }
            str += razao;
        }
        return str;
    }

    /**
     * Formata String para valor numerico
     * Ex: 1.236,98 para 1236.98
     * @param {string} value
     * @return {number}
     */
    public static formatMoneyToNumber(value): any {
        let tmpValue = '';
        if (Vue.prototype.$isEmpty(value)) {
            return null;
        }
        // se ja for um numero so retorna ele
        if (typeof value === 'number') {
            return value;
        }
        // so formata se for string
        tmpValue = value.replaceAll('.', '');
        tmpValue = value.replaceAll(',', '.');
        return Number(tmpValue);
    }

    /**
     * Limita o tamanho da string da coluna
     */
    public static formatSizeStringRow(value, len = 40, reduce = 37) {
        if (value.length > len) {
            return value.substring(0, reduce).concat('...');
        } else {
            return value;
        }
    }

    public static formatCnpjCpf(param): string {
        if (Vue.prototype.$isEmpty(param)) {
            return param;
        }
        if (param.length <= 11) {
            return param.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
        } else {
            return param.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
        }
    }
}
