export class StatusAccessRequest {

    public static PENDING = new StatusAccessRequest('PENDING', 0, 'Aguardando', 'warning');
    public static RELEASED = new StatusAccessRequest('RELEASED', 1, 'Liberado', 'success');
    public static DENIED = new StatusAccessRequest('DENIED', 2, 'Negado', 'danger');
    public static INVALID = new StatusAccessRequest('INVALID', 3, 'Inválido', '');

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
        private _color: string,
    ) {}

    public toString() {
        return this._key;
    }

    get key(): string {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }

    get color(): string {
        return this._color;
    }
}

export class StatusAccessRequestLookup {

    public static lookup = [
        StatusAccessRequest.PENDING,
        StatusAccessRequest.RELEASED,
        StatusAccessRequest.DENIED,
        StatusAccessRequest.INVALID,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = StatusAccessRequest.PENDING;

    public static getStatusByKey(value: string) {
        return this.lookup.find((v) => v.key === value) || this.default;
    }

    public static getStatusByValue(value: number) {
        return this.lookup.find((v) => v.value === value) || this.default;
    }

    private constructor() {}
}
