import Vue, {PluginObject} from 'vue';
import axios from 'axios';

// Full config:  https://github.com/axios/axios#request-config
const config = {
    baseURL: process.env.baseURL || process.env.apiUrl || '',
    timeout: 60 * 1000,
    withCredentials: true,
    auth: {
        username: 'hermes',
        password: 'tn3m@ster',
    },
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
    (cfg) => {
        const token = localStorage.getItem('token') || '';
        if (token) {
            cfg.headers.jwt = token;
        }
        return cfg;
    },
    (err) => {
        return Promise.reject(err);
    },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (res) => {
        // Do something with response data
        return res;
    },
    (err) => {
        // Vue.$toast.error('Ocorreu um problema ao realizar esta operação, tente novamente mais tarde!');
        // todo aqui ta forcando ou logout quando ocorre erro em requisicoes
        // localStorage.clear();
        return Promise.reject(err);
    },
);

const Plugin: PluginObject<any> = {
    install: (vue) => {
        vue.$axios = axiosInstance;
    },
};

Plugin.install = (vue) => {
    vue.$axios = axiosInstance;
    window.axios = axiosInstance;
    Object.defineProperties(Vue.prototype, {
        $axios: {
            get() {
                return axiosInstance;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
