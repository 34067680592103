import Vue from 'vue';
import * as VueJWT from 'vuejs-jwt';

Vue.use(
    VueJWT,
    {
        signKey: new Buffer('Yn2kjibddFAWtnPJ2AFlL8WXmohJMCvigQggaEypa5E=', 'base64'),
        keyName: 'token',
        ignoreExpiration: false,
        ignoreNotBefore: true,
    },
);
