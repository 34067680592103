import Vue from 'vue';
import vue_moment from 'vue-moment';
import moment from 'moment';

Vue.use(vue_moment);

const dateUtils = {
    brDate: (value) => {
        if (!value) {
            return  '';
        }
        const date = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        return date !== 'Invalid date' ? date : '';
    },
    brDateTime: (value) => {
        if (!value) {
            return  '';
        }
        const date = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS-SSS').format('DD/MM/YYYY HH:mm:ss').toString();
        return date !== 'Invalid date' ? date : '';
    },
};

Vue.filter('brDate', dateUtils.brDate);
Vue.filter('brDateTime', dateUtils.brDateTime);

Vue.prototype.dateUtils = dateUtils;
