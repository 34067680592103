export class StatusTarefa {

  public static DEFAULT = new StatusTarefa('DEFAULT', 0, 'Pendente', '');
  public static EM_ABERTO = new StatusTarefa('EM_ABERTO', 1, 'Em Aberto', 'warning');
  public static BAIXA_MANUAL = new StatusTarefa('BAIXA_MANUAL', 2, 'Pago', 'success');
  public static CANCELADA = new StatusTarefa('CANCELADA', 3, 'Cancelada', '');
  public static PAGO = new StatusTarefa('PAGO', 4, 'Pago', 'success');
  public static RENEGOCIADA = new StatusTarefa('RENEGOCIADA', 5, 'Renegociada', 'info');
  public static PAGO_PARCIAL = new StatusTarefa('PAGO_PARCIAL', 6, 'Pag. Parcial', 'info');
  public static ABERTO_A_VENCER = new StatusTarefa('ABERTO_A_VENCER', 7, 'Em Aberto à vencer', 'info');
  public static ABERTO_VENCIDO = new StatusTarefa('ABERTO_VENCIDO', 8, 'Em Aberto vencido', 'info');

  private constructor(
    private _key: string,
    private _value: number,
    private _description: string,
    private _type: string,
  ) {}

  public toString() {
    return this._key;
  }

  get value(): number {
    return this._value;
  }

  get description(): string {
    return this._description;
  }

  get type(): string {
    return this._type;
  }

  get isPago(): boolean {
    return this._value === 2 || this._value === 4 || this._value === 5;
  }

  get isDisableRow(): boolean {
      return this._value === 3 || this._value === 5;
  }
}

export class StatusFaturaLookup {

  public static lookup = [
    StatusTarefa.DEFAULT,
    StatusTarefa.EM_ABERTO ,
    StatusTarefa.BAIXA_MANUAL,
    StatusTarefa.CANCELADA,
    StatusTarefa.PAGO,
    StatusTarefa.RENEGOCIADA,
    StatusTarefa.PAGO_PARCIAL,
    StatusTarefa.ABERTO_A_VENCER,
    StatusTarefa.ABERTO_VENCIDO,
  ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

  public static default = StatusTarefa.DEFAULT;

  private constructor() {}
}
