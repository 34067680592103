import {VueConstructor} from 'vue/types/umd';
import {EtapaRastreamentoLookup} from '@/enums/EtapaRastreamento';
import {StatusAccessRequestLookup} from '@/enums/StatusAccessRequest';
import {StatusFaturaLookup} from '@/enums/StatusTarefa';
import {Utils} from '@/utils/Utils';
import moment from 'moment';

export default {

    install(Vue: VueConstructor) {

        /*
        * filtro para Etapas de Rastreamento
        * @value: number
        */
        Vue.filter('etapaRastreamento', function etapaRastreamento(value) {
            return EtapaRastreamentoLookup.lookup[value] ? EtapaRastreamentoLookup.lookup[value].description : '';
        });

        /*
        * filtro para Status da Fatura
        * @value: number
        */
        Vue.filter('status', function status(value) {
            return StatusFaturaLookup.lookup[value] ?  StatusFaturaLookup.lookup[value].description : '';
        });

        Vue.filter('statusSolicitacaoAcesso', function statusSolicitacaoAcesso(value) {
            return StatusAccessRequestLookup.lookup[value] ?  StatusAccessRequestLookup.lookup[value].description : '';
        });

        /*
       * filtro para validação em formato monetario
       * @value: number
       */
        Vue.filter('monetario', function monetario(value) {
            const coma = value !== undefined ? `${value}`.replace('.', ',') : '0';
            const decimalParte = coma !== undefined ? coma.split(',')[1] : undefined;
            return decimalParte !== undefined ? `R$ ${coma}` : `R$ ${coma},00`;
        });

        Vue.filter('currencyStringToNumber', function currencyStringToNumber(value) {
            let newValue = 0;
            if (value === '' || value === undefined) {
                return newValue;
            }
            newValue = value.replaceAll('.', '');
            newValue = value.replaceAll(',', '.');
            return Number(newValue);
        });

        /**
         * Formata para Moeda padrao brasileiro
         */
        Vue.filter('formatCurrency', function formatCurrency(value) {
            if (Vue.prototype.$isEmpty(value)) {
                return 'R$ 0,00';
            }
            const tmpVal = Utils.formatMoneyToNumber(value);
            return Utils.formatterMoneyBR.format(tmpVal);
        });

       /*
        * Filtro para validação em decimal em substituição do ponto pela vírgula
        * @value: number
        */
        Vue.filter('decimal', function decimal(value) {
            return value !== undefined ? `${value}`.replace('.', ',') : `0,00`;
        });

        /*
         * Filtro para cpf ou cnpj
         * @param
         */
        Vue.filter('cpfCnpj', function cpfCnpj(param) {
            if (Vue.prototype.$isEmpty(param)) {
                return param;
            }
            if (param.length <= 11) {
                return param.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
            } else {
                return param.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
            }
        });

        Vue.filter('formatBrDate', function formatDateToBR(value) {
            if (!value) {
                return  '';
            }
            const date = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
            return date !== 'Invalid date' ? date : '';
        });
    },
};
