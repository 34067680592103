






import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';

const LoadingStore = namespace('LoadingStore');

@Component({
    name: 'LoadingOverlay',
})
export default class LoadingOverlay extends Vue {
    @LoadingStore.State
    public isLoading!: boolean;
}
