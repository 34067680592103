import variables from '/src/scss/element-variables.scss';

export class EtapaRastreamento {

    public static DEFAULT = new EtapaRastreamento('DEFAULT', 0, 'Aguardando', '',
        'hourglass-start', variables.colordefault);
    public static PREPARANDO = new EtapaRastreamento('PREPARANDO', 1, 'Preparando', 'info',
        'archive', variables.colorinfo);
    public static TRANSPORTE = new EtapaRastreamento('TRANSPORTE', 2, 'Transportando',
        'warning', 'truck', variables.colorwarning);
    public static ENTREGANDO = new EtapaRastreamento('ENTREGANDO', 3, 'Entregando',
        'primary', 'street-view', variables.colorprimary);
    public static ENTREGUE = new EtapaRastreamento('ENTREGUE', 4, 'Entregue',
        'success', 'thumbs-up', variables.colorsuccess);

    private constructor(
        private _key: string,
        private _value: number,
        private _description: string,
        private _type: string,
        private _icon: string,
        private _color: string,
    ) {
    }

    public toString() {
        return this._key;
    }

    get value(): number {
        return this._value;
    }

    get description(): string {
        return this._description;
    }

    get type(): string {
        return this._type;
    }

    get icon(): string {
        return this._icon;
    }

    get color(): string {
        return this._color;
    }
}

export class EtapaRastreamentoLookup {

    public static lookup = [
        EtapaRastreamento.DEFAULT,
        EtapaRastreamento.PREPARANDO,
        EtapaRastreamento.TRANSPORTE,
        EtapaRastreamento.ENTREGANDO,
        EtapaRastreamento.ENTREGUE,
    ].sort((a, b) => a.value - b.value); // mantém o array ordenado pelo valor

    public static default = EtapaRastreamento.DEFAULT;

    private constructor() {
    }
}
