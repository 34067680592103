








































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {AppInfo} from '@/models/AppInfo';

const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'auth',
})
export default class Auth extends Vue {

    public $refs!: {
        form: Form,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    public loading: boolean = false;

    public windowWidth: number = window.innerWidth;

    public mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    public clean() {
        this.$refs.form.resetFields();
        this.loading = false;
    }
}
