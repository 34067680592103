import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Auth from '../views/Auth.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            meta: {
                requiresAuth: true,
            },
            component: Home,
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
                },
                {
                    path: '/orders',
                    name: 'Orders',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
                },
                /*{
                    path: '/includecollection',
                    name: 'IncludeCollection',
                    meta: {
                        requiresAuth: true,
                        permission: 'ACESSAR_MODULO_COLETAS',
                    },
                    component: () => import('../views/form/IncludeCollection.vue'),
                },*/
                {
                    path: '/freight',
                    name: 'Freight',
                    meta: {
                        requiresAuth: true,
                        permission: 'ACESSAR_MODULO_FRETE',
                    },
                    component: () => import('../views/Freight.vue'),
                },
                {
                    path: '/finance',
                    name: 'Finance',
                    meta: {
                        requiresAuth: true,
                        permission: 'ACESSAR_MODULO_FATURA',
                    },
                    component: () => import(/* webpackChunkName: "finance" */ '../views/Finance.vue'),
                },
                {
                    path: '/edi',
                    name: 'EDI',
                    meta: {
                        requiresAuth: true,
                        permission: 'ACESSAR_MODULO_EDI',
                    },
                    component: () => import('../views/Edi.vue'),
                },
                {
                    path: '/collection',
                    name: 'Collection',
                    meta: {
                        requiresAuth: true,
                        permission: 'ACESSAR_MODULO_COLETAS',
                    },
                    component: () => import('../views/CollectionRequest.vue'),
                },
            ],
        },
        {
            path: '/auth',
            name: 'Auth',
            redirect: '/login',
            meta: {
                requiresAuth: false,
            },
            component: Auth,
            children: [
                {
                    path: '/register',
                    name: 'Register',
                    meta: {
                        requiresAuth: false,
                    },
                    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
                },
                {
                    path: '/forgot/:email?',
                    name: 'Forgot',
                    meta: {
                        requiresAuth: false,
                    },
                    component: () => import(/* webpackChunkName: "forgot" */ '../views/Forgot.vue'),
                },
                {
                    path: '/reset',
                    name: 'Reset',
                    meta: {
                        requiresAuth: false,
                    },
                    component: () => import(/* webpackChunkName: "forgot" */ '../views/Reset.vue'),
                },
                {
                    path: '/login/:email?',
                    name: 'Login',
                    component: Login,
                },
            ],
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
        },
    ] as RouteConfig[],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (localStorage.getItem('token') == null) {
            next({name: 'Login'});
        } else {
            next(); // go to wherever I'm going
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
});

export default router;
