










import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'SuccessMessage',
})
export default class SuccessMessage extends Vue {
  @AuthStore.State
  public successMessage!: string;
}
