






import {Component, Vue} from 'vue-property-decorator';
import TermsOfUse from '@/components/terms/TermsOfUse.vue';
import PrivacyPolicy from '@/components/terms/PrivacyPolicy.vue';

@Component({
    name: 'termsAndPolicy',
    components: {TermsOfUse, PrivacyPolicy},
})
export default class TermsAndPolicy extends Vue {

}
