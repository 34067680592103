







































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {AppInfo} from '@/models/AppInfo';
import {User} from '@/models/User';
import Recaptcha from '@/components/register/Recaptcha.vue';
import Copyright from '@/components/commons/Copyright.vue';
import SuccessMessage from '@/components/commons/SuccessMessage.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import TermsAndPolicy from '@/components/terms/TermsAndPolicy.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'login',
    components: {Recaptcha, Copyright, SuccessMessage, ErrorMessage, TermsAndPolicy},
})
export default class Login extends Vue {

    public $refs!: {
        form: Form,
        recaptcha: Recaptcha,
        copyright: Copyright,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.Action
    public login!: (user: User) => Promise<string>;

    @AuthStore.State
    public successMessage!: string;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    public windowWidth: number = window.innerWidth;

    public loading: boolean = false;

    public model: any = {login: '', password: ''};

    public rules: any = {
        login: [
            {
                required: true,
                message: 'O e-mail é obrigatório',
                trigger: 'blur',
            },
            {
                type: 'email',
                message: 'O formato do e-mail não é válido',
                trigger: 'blur',
            },
            {
                min: 4,
                message: 'O e-mail deve ter no mínimo 4 caracteres',
                trigger: 'blur',
            },
        ],
        password: [
            {
                required: true,
                message: 'Senha é obrigatória',
                trigger: 'blur',
            },
            {
                min: 6,
                message: 'A senha deve ter no mínimo 6 caracteres',
                trigger: 'blur',
            },
        ],
    };

    public mounted() {
        this.setEmailFromRouteParams();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    public async onLogin(): Promise<any> {
        await this.login(this.model)
            .then((msg) => {
                this.setSuccessMessage(msg);
                this.setErrorMessage('');
                setTimeout(() => {
                    this.setSuccessMessage('');
                    this.$router.push('dashboard');
                }, 3000);
            })
            .catch((msg) => {
                this.setErrorMessage(msg);
                this.setSuccessMessage('');
            });
    }

    public async submit() {
        this.$refs.form.validate(async (valid) => {
            if (!valid) {
                return;
            } else {
                if (!this.$refs.recaptcha.isChecked) {
                    this.setErrorMessage('Preencha o reCAPTCHA para avançar!');
                    return;
                }
            }

            this.loading = true;
            await this.onLogin();
            this.loading = false;
        });
    }

    public register() {
        this.$router.push('register');
    }

    public forgot() {
        this.$router.replace({path: '/forgot/' + this.model.login});
    }

    public clean() {
        this.$refs.form.resetFields();
        this.loading = false;
    }

    public created(): void {
        this.setSuccessMessage('');
    }

    public setEmailFromRouteParams(): void {
        if (this.$route.params.email != null) {
            this.model.login = this.$route.params.email;
        }
    }
}
