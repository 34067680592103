import Vue from 'vue';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import {Authority} from '@/models/Authority';
import {StatusAccessRequest} from '@/enums/StatusAccessRequest';
import Routes from '@/router';

@Module({ namespaced: true })
class AuthStore extends VuexModule {

    public toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];
    public token: string = localStorage.getItem('token') || '';
    public status: string = '';
    public recoveryMail: string = '';
    public errorMessage: string = '';
    public successMessage: string = '';
    public warningMessage: string = '';
    public currentStatusFilter?: StatusAccessRequest = undefined;
    public user: User = JSON.parse(localStorage.getItem('user') || '{}');
    public currentCompany: Company = JSON.parse(localStorage.getItem('company') || '{}');
    public companyList: Company[] = [] as Company[];
    public authorityList: Authority[] = [] as Authority[];

    get isAuthenticated(): boolean {
        return !!this.token;
    }

    @Mutation
    public async setState(newState: boolean): Promise<void> {
        this.state = newState;
    }

    @Mutation
    public async setToken(newToken: string): Promise<void> {
        await localStorage.setItem('token', newToken);
        this.token = newToken;
    }

    @Mutation
    public async setRecoveryMail(newRecoveryMail: string): Promise<void> {
        this.recoveryMail = newRecoveryMail;
    }

    @Mutation
    public async setErrorMessage(newErrorMessage: string): Promise<void> {
        this.errorMessage = newErrorMessage;
    }

    @Mutation
    public async setSuccessMessage(newSuccessMessage: string): Promise<void> {
        this.successMessage = newSuccessMessage;
    }

    @Mutation
    public async setWarningMessage(newWarningMessage: string): Promise<void> {
        this.warningMessage = newWarningMessage;
    }

    @Mutation
    public async setCurrentStatusFilter(newStatusFilter: StatusAccessRequest | undefined): Promise<void> {
        this.currentStatusFilter = newStatusFilter;
    }

    @Mutation
    public async setUser(newUser: User): Promise<void> {
        await localStorage.setItem('user', JSON.stringify(newUser));
        this.user = newUser;
    }

    @Mutation
    public async setCurrentCompany(newCompany: Company): Promise<void> {
        await localStorage.setItem('company', JSON.stringify(newCompany));
        this.currentCompany = newCompany;
    }

    @Mutation
    public async setCompanyList(newCompanyList: Company[]): Promise<void> {
        this.companyList = newCompanyList;
    }

    @Mutation
    public async setAuthorityList(newAuthorityList: Authority[]): Promise<void> {
        this.authorityList = newAuthorityList;
    }

    @Action({rawError: true})
    public async login(user: User): Promise<string> {
        user.isMobile = this.toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
        this.context.commit('setState', 'loading');
        return new Promise<string>((resolve, reject) => {
            Vue.$axios
                .post('/auth/sign-in', user)
                .then((response) => {
                    const jwt = response.data!.token;
                    this.context.commit('setToken', jwt);
                    this.context.commit('setUser', {login: user.login});
                    this.context.commit('setState', 'success');
                    resolve('Usuário autenticado com sucesso!');
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data);
                    } else {
                        reject('Erro ao processar login.');
                    }
                });
        });
    }

    @Action({rawError: true})
    public async updateAuthorities(): Promise<void> {
        try {
            const authString = Vue.$jwt.decode().authorities;
            const authList = JSON.parse(authString.replace('\\', ''));
            this.context.commit(
                'setAuthorityList',
                authList.map((auth: string) => {
                    const splittedAuth = auth.split(/_(.+)/);
                    return new Authority(
                        Number(splittedAuth[0]),
                        splittedAuth[1],
                    );
                }),
            );
        } catch (e) {
            await this.context.dispatch('logout', this.logout)
                .then(() => {
                    Routes.push('/login').catch((err) => {});
                });
        }
    }

    @Action({rawError: true})
    public async logout(): Promise<void> {
        this.context.commit('setToken', '');
        this.context.commit('setState', '');
        this.context.commit('setUser', {} as User);
        this.context.commit('setCurrentCompany', {} as Company);
        this.context.commit('setCompanyList', [] as Company[]);
        return new Promise<void>((resolve, reject) => {
            localStorage.removeItem('token');
            resolve();
        });
    }

    @Action({rawError: true})
    public async findCompanyList(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!Vue.$jwt.hasToken()) {
                reject('Erro ao buscar informações de autenticação para requisição.');
                this.logout();
            }
            Vue.$axios.get(`/empresa/search/byUsuario/${this.user.login}`).then((response) => {
                    const companies = response.data;
                    if (companies.length) {
                        this.context.commit('setCompanyList', companies as Company[]);
                        this.context.commit('setCurrentCompany', companies[0] as Company);
                    } else {
                        this.context.commit('setCompanyList', [] as Company[]);
                        this.context.commit('setCurrentCompany', {} as Company);
                    }

                    resolve();
                })
                .catch((error) => {
                    reject('Erro ao buscar informações das empresas.');
                });
        });
    }

    @Action({rawError: true})
    public async setDedicatedCompany(dedicatedCompanyId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.context.commit('setCompanyList', [{id: dedicatedCompanyId}] as Company[]);
            this.context.commit('setCurrentCompany', {id: dedicatedCompanyId} as Company);
            resolve();
        });
    }

    @Action({rawError: true})
    public async updateCompany(companyId: number): Promise<void> {
        if (this.currentCompany.id === companyId) {
            return;
        }
        await this.context.commit(
        'setCurrentCompany',
            this.companyList.find((company) => company.id === companyId) || {} as Company,
        );
    }
}
export default AuthStore;
