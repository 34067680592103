






import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AppInfo} from '@/models/AppInfo';
import 'element-ui/lib/theme-chalk/display.css';

const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'app',
})
export default class App extends Vue {

    @AppInfoStore.Action
    public updateInfo!: (newInfo: AppInfo) => Promise<void>;

    public beforeCreate() {
        this.$axios
            .get('/appinfo/start')
            .then((response) => {
                const base64Pathern = 'data:image/jpg;base64,';
                const appInfo = response.data as AppInfo;
                if (!appInfo) {
                    return;
                }
                if (appInfo.logoHeader!.length) {
                    appInfo.logoHeader = base64Pathern + appInfo.logoHeader;
                } else {
                    delete appInfo.logoHeader;
                }
                if (appInfo.logoLogin!.length) {
                    appInfo.logoLogin = base64Pathern + appInfo.logoLogin;
                } else {
                    delete appInfo.logoLogin;
                }
                if (appInfo.logoHermes!.length) {
                    appInfo.logoHermes = base64Pathern + appInfo.logoHermes;
                } else {
                    delete appInfo.logoHermes;
                }
                if (appInfo.logoFavIcon!.length) {
                    appInfo.logoFavIcon = base64Pathern + appInfo.logoFavIcon;
                } else {
                    delete appInfo.logoFavIcon;
                }
                if (appInfo.logoClienteTopBar!.length) {
                    appInfo.logoClienteTopBar = base64Pathern + appInfo.logoClienteTopBar;
                } else {
                    delete appInfo.logoClienteTopBar;
                }
                if (appInfo.iconAccess!.length) {
                    appInfo.iconAccess = base64Pathern + appInfo.iconAccess;
                } else {
                    delete appInfo.iconAccess;
                }
                this.updateInfo(appInfo);

                this.setColorSchema(JSON.parse(response.data.portalColorSchema));
            });
    }

    private setColorSchema(schema: any) {
      const rootEl: HTMLElement | null = document.querySelector(':root');

      if (!rootEl) {
        return;
      }

      for (const prop of Object.keys(schema)) {
        rootEl.style.setProperty(prop, schema[prop]);
      }
    }
}
