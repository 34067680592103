




























import {namespace} from 'vuex-class';
import {Component, Vue} from 'vue-property-decorator';
import {Company} from '@/models/Company';
import {AppInfo} from '@/models/AppInfo';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'companySelect',
})
export default class CompanySelect extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public companyList!: Company[];

    @AuthStore.Action
    public findCompanyList!: () => Promise<void>;

    @AuthStore.Action
    public setDedicatedCompany!: (dedicatedCompanyId: number) => Promise<void>;

    @AuthStore.Action
    public updateCompany!: (companyId: number) => Promise<void>;

    public onUpdateCompany(companyId: number): void {
        this.updateCompany(companyId)
            .then(() => {
                if (this.$router.currentRoute.name !== 'Dashboard') {
                    this.$router.push({name: 'Dashboard'});
                }
            });
    }

    public created(): void {
        if (this.info.dedicatedCompanyId) {
            this.setDedicatedCompany(this.info.dedicatedCompanyId);
        } else {
            this.findCompanyList();
        }
    }

    public formatName(name: string, min: boolean): string {
        let returnVal: string = '';
        for (let i = 0; i < name.length; i++) {
            // Limita nome a 30 caracteres, ou quebra em espaco apos 25 caracteres
            if (i === 30 || (i > 25 && name.charAt(i) === ' ' && min)) {
                returnVal = returnVal.concat('...');
                break;
            }
            returnVal = returnVal.concat(name.charAt(i));
        }
        return returnVal;
    }
}
