import Vue from 'vue';
import Vuex from 'vuex';
import AppInfoStore from './modules/appinfo';
import AuthStore from './modules/auth';
import LoadingStore from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AppInfoStore,
    AuthStore,
    LoadingStore,
  },
});
