





























import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Topbar from '@/components/layout/Topbar.vue';
import Sidebar from '@/components/layout/Sidebar.vue';
import {AppInfo} from '@/models/AppInfo';
import LoadingOverlay from '@/components/commons/LoadingOverlay.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'Home',
    components: {LoadingOverlay, Topbar, Sidebar},
})
export default class Home extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    public collapsedWidth = '65px';

    public currentYear: number = new Date().getFullYear();

    get sideWidth(): string {
        const expandedWidth = window.screen.availWidth <= 380 ? window.screen.availWidth + 'px' : '240px';
        return this.info!.sidebarCollapsed ? this.collapsedWidth : expandedWidth;
    }

    get isShowFooter(): boolean {
        return this.$route.name === 'Dashboard';
    }
}
