


















import {Component, Vue} from 'vue-property-decorator';
import {AppInfo} from '@/models/AppInfo';
import {namespace} from 'vuex-class';

const AppInfoStore = namespace('AppInfoStore');

@Component({
    name: 'TermsOfUse',
})

export default class TermsOfUse extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    public loading: boolean = false;
    public showDialog: boolean = false;
}
