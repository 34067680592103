








































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {User} from '@/models/User';
import CompanySelect from '@/components/company/CompanySelect.vue';
import {AppInfo} from '@/models/AppInfo';
import {StatusAccessRequest} from '@/enums/StatusAccessRequest';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'Topbar',
    components: {CompanySelect},
})
export default class Topbar extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public user!: User;

    @AppInfoStore.Action
    public toggleSidebar!: () => void;

    @AuthStore.Action
    public logout!: () => Promise<void>;

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.Mutation
    public setCurrentStatusFilter!: (newStatusFilter: StatusAccessRequest | undefined) => Promise<void>;

    // contador inicial do badger das permissoes de acesso
    public defaultValueBadge: number = 0;

    // primary / success / warning / danger / info
    public defaultTypeBadger: string = 'danger';

    public isCollapsed: boolean = false;

    public isParamsExists() {
        return !Vue.prototype.$isEmpty(this.currentCompany.id) && !Vue.prototype.$isEmpty(this.user.login);
    }

    /**
     * Atualiza contador de alertas
     */
    public actionUpdateBadge() {
        if (this.isParamsExists()) {
            this.$axios
                .get(`/acesso/atualizaalerta/${this.user.login}/${this.currentCompany.id}`)
                .then((response) => {
                    if (!Vue.prototype.$isEmpty(response.data)) {
                        this.defaultValueBadge = response.data;
                        this.defaultTypeBadger = response.data > 0 ? 'success' : 'danger';
                        this.$emit('updateBadgeCount', this.defaultValueBadge);
                        this.$emit('updateBadgeType', this.defaultTypeBadger);
                    }
                }).catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
     * Consulta autorizacoes pendentes
     */
    public consultaAlertaAutorizacoes() {
        if (this.isParamsExists()) {
            this.$axios
                .get(`/acesso/consultaautorizacao/${this.user.login}/${this.currentCompany.id}`)
                .then((response) => {
                    if (!Vue.prototype.$isEmpty(response.data)) {
                        this.defaultValueBadge = response.data;
                        this.defaultTypeBadger = response.data > 0 ? 'success' : 'danger';
                        this.$emit('updateBadgeCount', this.defaultValueBadge);
                        this.$emit('updateBadgeType', this.defaultTypeBadger);
                    }
                }).catch((error) => {
                console.error(error);
            });
        }
    }

    public onDashboard(): void {
        this.$router.push('');
    }

    // @Watch('$route')
    public onAccessRequest(index: string): void {
        this.setCurrentStatusFilter(undefined);
        if (this.$router.currentRoute.name !== index) {
            this.$router.push({name: index});
        }
    }

    public onMenuSelect(index: string): void {
        if (this.$router.currentRoute.name === 'Orders') {
            this.setCurrentStatusFilter(undefined);
        }
        if (this.$router.currentRoute.name !== index && index) {
            this.$router.push({name: index});
        }
    }

    public onLogout(): void {
        this.logout().then(() => this.$router.replace({ path: '/login' }));
    }

    public handleCommand(command): void {
        if (command === 'logout') {
            this.onLogout();
            return;
        }
        if (this.$route.path !== ('/' + command)) {
            this.$router.push(command);
        }
    }

    public mounted(): void {
        this.$nextTick(() => {
            this.consultaAlertaAutorizacoes();
            this.isCollapsed = this.info.sidebarCollapsed;
        });
    }

    public updated(): void {
        this.isCollapsed = this.info.sidebarCollapsed;
    }
}
