import Vue from 'vue';
import ElementUI, {Message, Notification} from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br';

Vue.use(ElementUI, {locale});

const baseOptions = {
  duration: 6000,
  offset: 0,
  onClose: () => {
    return;
  },
};

Vue.prototype.$message = {
  success: (message: string) => Message({
    message,
    type: 'success',
    showClose: true,
  }),
  error: (message: string) => Message({
    message,
    type: 'error',
    showClose: true,
  }),
  warning: (message: string) => Message({
    message,
    type: 'warning',
    showClose: true,
  }),
};

Vue.prototype.$notify = {
  success: (message: string) => Notification({
    ...baseOptions,
    message,
    title: 'Sucesso!',
    type: 'success',
    position: 'bottom-right',
    iconClass: 'fa-check-circle',
    // customClass: 'notification-success',
  }),
  warning: (message: string) => Notification({
    ...baseOptions,
    message,
    title: 'Atenção!',
    type: 'warning',
    position: 'bottom-right',
    iconClass: 'fa fa-exclamation-circle',
    // customClass: 'notification-warning',
  }),
  error: (message: string) => Notification({
    ...baseOptions,
    message,
    title: 'Erro!',
    type: 'error',
    position: 'bottom-right',
    iconClass: 'fa fa-times-circle',
    // customClass: 'notification-error',
  }),
  info: (message: string) => Notification({
    ...baseOptions,
    message,
    title: 'Informação!',
    type: 'info',
    position: 'bottom-right',
    iconClass: 'fa fa-info-circle',
    // customClass: 'notification-info',
  }),
};
