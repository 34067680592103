




























import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AppInfo} from '@/models/AppInfo';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import {Authority} from '@/models/Authority';
import {Route} from 'vue-router';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'Sidebar',
})
export default class Sidebar extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    public currentRoute = 'Dashboard';

    @Watch('$route')
    public currentRouteWatcher(to: Route, from: Route): void {
        this.currentRoute = to.name || '';
    }

    public onMenuSelect(index: string): void {
        if (this.$router.currentRoute.name !== index) {
            this.$router.push({name: index});
        }
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    public mounted(): void {
        this.currentRoute = this.$route.name!;
    }
}
