import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {AppInfo} from '@/models/AppInfo';

@Module({namespaced: true})
class AppInfoStore extends VuexModule {

    public info: AppInfo = {
        dedicated: false,
        dedicatedCompanyId: null,
        logoHeader: require('@/assets/logo.png'),
        logoLogin: require('@/assets/logo.png'),
        logoHermes: require('@/assets/logo.png'),
        logoFavIcon: require('@/assets/logo.png'),
        iconAccess: require('@/assets/truck_home.png'),
        logoClienteTopBar: require('@/assets/logo.png'),
        termosDeUso: '',
        politicaPrivacidade: '',
        title: 'Hermes TMS',
        version: 'DEV-SNAPSHOT',
        ready: false,
        sidebarCollapsed: localStorage.getItem('sidebarCollapsed') === 'true' || false,
        expirationAccessRequest: 1,
        googleRecaptchaToken: '',
        mensagemCapa: '',
        alteraDataEntrega: false,
    };

    get titleUpperCase(): string {
        return this.info.title.toUpperCase();
    }

    @Mutation
    public async setInfo(newInfo: AppInfo): Promise<void> {
        this.info = {...this.info, ...newInfo, ready: true};
    }

    @Action
    public updateInfo(newInfo: AppInfo): void {
        this.context.commit('setInfo', newInfo);
    }

    @Action
    public toggleSidebar(): void {
        const currentSidebarStatus = !this.info.sidebarCollapsed;
        localStorage.setItem('sidebarCollapsed', String(currentSidebarStatus));
        this.context.commit('setInfo', {sidebarCollapsed: currentSidebarStatus});
    }
}

export default AppInfoStore;
